import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a343622"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = ["innerHTML", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "box-card existing_leads",
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "close-on-press-escape": false,
    "close-on-click-modal": false,
    width: _ctx.screen.width > 768 ? '380px' : '100%'
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('select_existing_lead')), 1)
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existingLeads, (existingLead) => {
        return (_openBlock(), _createElementBlock("h4", {
          key: existingLead.map_lead_id,
          innerHTML: existingLead.name,
          class: "text item",
          onClick: ($event: any) => (_ctx.setDetails(existingLead))
        }, null, 8, _hoisted_2))
      }), 128)),
      _createVNode(_component_el_divider),
      _createVNode(_component_el_button, {
        type: "primary",
        size: "large",
        onClick: _ctx.newDetails
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('enter_new_details')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "width"]))
}