export const quoteTab = [
    {
        title: 'all',
    },
    {
        title: 'basic',
    },
    {
        title: 'enhanced',
    },
]