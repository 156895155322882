import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Health, Information, GroupHealth } from '@/core/types';
import router from '@/router';
import moment from "moment";
import { store } from "@/store";
import { thankYouPopup, insuredFamilyValue, insuredParentValue, insuredDomesticWorkerValue } from "@/store/stateless"
import { isCalled } from '@/store/stateless';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Health
  ): void;
  [PersonalActionTypes.POST_GROUP_HEALTH_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: GroupHealth
  ): any;
  [PersonalActionTypes.GET_GROUP_HEALTH_CUSTOMER](
    { commit }: AugmentedActionContext,
    payload: GroupHealth
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload) {
    // commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, true);
    isCalled.mainLoader = true
    try {

      if (!payload.update_insurance_type &&  [insuredFamilyValue,insuredParentValue].includes(payload.insured_type_id)) {
        payload.insurance_type = 0; 
     }

      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data.data;
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      // commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);
      isCalled.mainLoader = false
      const members = personal_details.lead_information.map((x: Information) => {
        return {
          value: x.id,
          label: x.member_name
        }
      })
      commit(PersonalMutationTypes.SET_CUSTOMER_MEMBERS, members);
      const ages = payload.members.map((x) => {
          return x.age;
      })
      ages.unshift(payload.age);
      const filtereCustomer = customer(personal_details.customer, payload, personal_details.health_lead_map_id);
      // console.log({filtereCustomer})

      window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
      window.sessionStorage.setItem('session_id', personal_details.session_id);
      window.sessionStorage.setItem('pd', JSON.stringify(filtereCustomer));
      commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);

      window.localStorage.setItem('map_id', personal_details.health_lead_map_id)

      commit(PersonalMutationTypes.SET_CUSTOMER_AGES, ages.filter(x => x !== 0));
      window.localStorage.setItem('personal_detail_submitted', JSON.stringify(payload));
      // const routeName = !payload.is_declaration_submitted ? 'HealthDeclaration' : 'CompareQuotes'

      if(payload.update_insurance_type)
      {
        console.log('tests');
        // updating interested
        return data;
      }
      console.log('tests1');
      

      if(filtereCustomer.is_investor){
        // if is investor is true then redirect to thank you page
        window.localStorage.setItem('quotes_not_found','YES');
        const route = router.resolve({ name: 'CallBack' });
        window.location.href = route.href;
        return data;
      }
      console.log('tests1');
      
      const routeName = 'HealthDeclaration'
      const route = router.resolve({ name: routeName, query: { map_id: personal_details.health_lead_map_id } });
      // const route = router.resolve({ name: routeName });
      
      const cd = store.state.personals.customer
      // console.log('cd',cd);
      if ([insuredFamilyValue,insuredParentValue,insuredDomesticWorkerValue].includes(cd.insured_type_id)) {
          const members = cd.members
          // console.log(members);
          // if (members.length == 0) {
            if (members.length == 1 && members[0]?.is_temp == 1) {
              // cd.members = [];
              thankYouPopup.open = true; // show popup in case of family and parent with no members add
              return data;
          }
      }
      // console.log('route')
      window.location.href = route.href;
    }
    catch (err) {
      isCalled.mainLoader = false

      return err;

    }
  },

  async [PersonalActionTypes.GET_LATEST_LEADS] ({commit}, payload) {
    try {
      const data = await axios.post(Links.GET_LATEST_LEADS_BY_INSURANCE_ID, payload) 
      if(typeof data.data === 'object') {

        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_LEADS, data.data)
        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, mapAllLeads(data.data))
        
        return true
      }
      commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, [])
      commit(PersonalMutationTypes.SET_INSURED_TYPE_DISABLED, false)
      return false
    } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.GET_PERSONAL_DETAILS] ({commit}, payload) {
    try {
      const data = await axios.post(Links.GET_PERSONAL_DETAILS, payload) 
      
      return data.data.data.customer;
    } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.SET_CUSTOMER_LEADS]({ commit }, payload) {
    const { customer, insured_city, insured_type_id, insurance_type, leads, agent, additional, id, contact_person, is_declaration_submitted }  = payload
        const health = {
          id: customer.id,
          name: customer.name,
          email: customer.email,
          contact_person,
          phone_number: customer.phone_number_without_code,
          phone_country_code: customer.phone_country_code,
          insured_city,
          insured_type_id,
          insurance_type,
          is_salary: additional && additional.is_salary === 1 ? true : false,
          expected_insurance_start_date: additional ? additional.expected_insurance_start_date : null,
          expected_premium: additional ? additional.expected_premium : null,
          customer_source: customer.customer_source,
          is_health: 1,
          utm_source: customer.utm_source,
          utm_medium: customer.utm_medium,
          utm_campaign: customer.utm_campaign,
          utm_content: customer.utm_content,
          dob: additional ? additional.dob : null,
          nationality_id: additional ? additional.nationality_id : 0,
          gender: additional ? additional.gender : null,
          is_married: additional && additional.is_married === 1 ? true : false,
          company_name: null,
          is_self: additional && additional.is_self === 1 ? true : false,
          agent: agent ? agent.id : null,
          health_lead_map_id: id,
          deleted_members:[],
          members: members(leads, insurance_type),
          categories: [],
          is_declaration_submitted,
          is_web: true,
          hasDealInvoice: false,
          is_investor: payload.is_investor === 1 ? true : false
        }

        console.log('setting',health)

    commit(PersonalMutationTypes.SET_CUSTOMER, health)
  },

  async [PersonalActionTypes.POST_GROUP_HEALTH_PERSONAL_DETAILS]({ commit }, payload) {
    commit(PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER_LOADING, true);
    try {
      const data = await axios.post(Links.POST_GROUP_HEALTH_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data;
      // console.log('personal_details',personal_details)
      const filtereCustomer = customerGroupHealth(personal_details.customer, payload);
      commit(PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER, filtereCustomer);

      // window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
      window.sessionStorage.setItem('pd', JSON.stringify(filtereCustomer));
      window.sessionStorage.setItem('session_id', personal_details.session_id);
      
      if(data.data.data.uuid  != window.sessionStorage.getItem('uuid')) {
        window.sessionStorage.setItem('uuid', data.data.data.uuid)
      } 
      // console.log(data.data.data);
      commit(PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER_LOADING, false);      
      const route = router.resolve({ name: 'ThankYou' });
      window.location.href = route.href;
    }
    catch (err) {
      console.log(err);
      return err;
    }
  },
  async [PersonalActionTypes.GET_GROUP_HEALTH_CUSTOMER]({ commit }, payload) {
    try {
      if(payload.cid || (window.sessionStorage.getItem('uuid') != '' && window.sessionStorage.getItem('uuid') != 'undefined' && payload.name != '')) {
        const data = await axios.post(Links.GET_GROUP_HEALTH_CUSTOMER, payload);
        let personal_details = data.data.data.customer;

        if(payload.cid)
           personal_details = data.data.data;
        
        const filtereCustomer = customerGroupHealth(personal_details, payload);
        commit(PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER, filtereCustomer);
        
        commit(PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER_LOADING, false);      
        window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
        return true;
      }
    }
    catch (err) {
      return true;
      return err;
    }
  },

};

function customer(data, payload, map_id) {
  const { customer, insured_city, insured_type_id, insurance_type, leads, agent, additional, id, is_declaration_submitted }  = data
      // const pd = await personalDetails(leads, customer.name)
      const query = new Map(Object.entries(payload.query))
      query.set('map_id', map_id);
      const sessionId = window.sessionStorage.getItem('session_id');
     
      const health = {
        id: customer.id,
        lang: payload.lang,
        name: customer.name,
        email: customer.email,
        phone_number: customer.phone_number_without_code,
        phone_country_code: customer.phone_country_code,
        insured_city,
        insured_type_id,
        insurance_type,
        is_salary: additional && additional.is_salary === 1 ? true : false,
        expected_insurance_start_date: additional ? additional.expected_insurance_start_date : null,
        expected_premium: additional ? additional.expected_premium : null,

        customer_source: customer.customer_source,
        is_health: 1,
        utm_source: customer.utm_source,
        utm_medium: customer.utm_medium,
        utm_campaign: customer.utm_campaign,
        utm_content: customer.utm_content,
        age: payload.age,
        dob: additional ? additional.dob : null,

        nationality_id: additional ? additional.nationality_id : 0,
        gender: additional ? additional.gender : null,
        is_married: additional && additional.is_married === 1 ? true : false,
        company_name: null,
        is_self: additional && additional.is_self === 1 ? true : false,
        agent: agent ? agent.id : null,
        is_company: payload.is_company,
        health_lead_map_id: id,
        deleted_members:[],
        members: leads && leads.length > 0 ? members(leads, insurance_type) : [],
        categories: [],
        query: Object.fromEntries(query),
        is_declaration_submitted,
        is_web: true,
        hasDealInvoice: false,
        session_id:sessionId ?? null,
        update_insurance_type:false,
        is_investor:payload.is_investor
      }
    return health;
}

function members(leads, insurance_type) {
  if(insurance_type === 1) {
    return leads.map((x, index) => {
      
        return {
          id: x.lead_information.id,
          lead_id: x.lead_information.lead_id,
          member_name: x.lead_information.is_temp === 0 ? x.lead_information.member_name : null,
          relationship: x.lead_information.is_temp === 0 ? x.lead_information.relationship : null,
          gender: x.lead_information.is_temp === 0 ?  x.lead_information.gender : null,
          age: x.lead_information.is_temp === 0 ?  getAge(x.lead_information.dob) : null,
          dob: x.lead_information.is_temp === 0 ? x.lead_information.dob : null,  
          nationality_id: x.lead_information.is_temp === 0 ? x.lead_information.nationality_id : null,
          is_salary: x.lead_information.is_temp === 0 ? x.lead_information.is_salary === 1 ? true : false : false,
          declaration_answers: x.lead_information.is_temp === 0 ? x.lead_information.declaration_answers : [],
          is_married: x.lead_information.is_temp === 0 ? x.lead_information.is_married : false,
          is_editable: leads.length === (index + 1) ? true : false,
          is_temp:x.lead_information.is_temp

        }
    })
  } else {
    // console.log('else');
    return leads.map(x => {
      return x.lead_informations.map((y, index) => {
        return {
          id: y.id,
          lead_id: y.lead_id,
          member_name: y.is_temp === 0 ? y.member_name : null,
          relationship: y.is_temp === 0 ?  y.relationship : null,
          gender: y.is_temp === 0 ?  y.gender : null,
          age: y.is_temp === 0 ?  getAge(y.dob) : null,
          dob: y.is_temp === 0 ?  y.dob  : null, 
          nationality_id: y.is_temp === 0 ?  y.nationality_id : null,
          is_salary: y.is_temp === 0 ?  y.is_salary === 1 ? true :  false : false,
          declaration_answers: y.is_temp === 0 ?  y.declaration_answers : [],
          is_married: y.is_temp === 0 ?  y.is_married : false,
          is_editable: x.lead_informations.length === (index + 1) ? true : false,
          is_temp:y.is_temp

        }
      })
    }).flat()
  }
}

function getAge(e: Date) {
    const today = moment().format('YYYY-MM-DD')
    return moment(today, 'YYYY-MM-DD').diff(moment(e, "YYYY-MM-DD"), 'years', true);
}

function mapAllLeads(data: any) {
  const value = data.map(x => x.insured_type_id)
  return [...new Set(value)]
}

function customerGroupHealth(data, payload) {
  const customerD  = data
  const customer = customerD.customer;
  const uuid = window.sessionStorage.getItem('uuid');
  const cid = window.sessionStorage.getItem('cid');
  const isRenewal = window.sessionStorage.getItem('renewal');
  let is_renewal = false;
  if(isRenewal)
      is_renewal = true;

  const groupHealth = {
    id: 0,
    lang: payload.lang,
    email: (customer.email != 'QL') ? customer.email : null,
    name: (customer.name != 'QL') ? customer.name : null,
    contact_person:  customerD?.contact_person ?? null,
    phone_number: (customer.phone_number != 'QL') ? customer.phone_number_without_code : null,
    phone_country_code: customer.phone_country_code ?? '+971',
    no_of_members:  customerD?.group_size ?? 0,
    customer_source: customer.customer_source ?? null,
    utm_source: customer.utm_source ?? null,
    utm_medium: customer.utm_medium ?? null,
    utm_campaign: customer.utm_campaign ?? null,
    utm_content: customer.utm_content ?? null,
    uuid: uuid,
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 1,
    is_salary: true,
    expected_premium:null,
    is_health: 1,
    age: 0,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    is_company: false,
    query: {},
    is_web: true,
    session_id:null,
    update_insurance_type:false,
    is_investor: false,
    members:[],
    
    is_renewal:is_renewal,
    cid: cid ?? null


  }
  return groupHealth;
}
