
import { currency } from "@/core/functions/currency";
import Quotes from "@/core/services/Quotes";
import router from "@/router";
import { ElLoading } from "element-plus";
import { defineComponent, ref, toRef, computed, onMounted } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { store } from "@/store";
export default defineComponent({
    props: {
        quote: {
            type: Object,
            required: true
        },
        expand: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    setup(_, context) {

        const captcha = useReCaptcha();

        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)

        const copaySelection = computed(() => {
            return store.state.quotes.copay_selection
        });

        const quote = toRef(_, 'quote');
        const moreDetails = ref(false);
        const assetDomain = process.env.VUE_APP_ASSETS_DOMAIN;
        // const premiums = quote.value.memberPremiums
        const premiums = computed(() => {
            return quote.value.memberPremiums
        })
        const selectedPremium = ref(premiums.value[0])

        const changeCopay = (index) => {
            selectedPremium.value = premiums.value[index];
        }

        onMounted(async () => {
            
            const selections = window.sessionStorage.getItem('copaySelection');
            if(selections){
                const result = JSON.parse(selections);
                // console.log(result,quote.value.id);

                const key = result.forEach((item) =>{ 
                    if(item.id == quote.value.id){
                        selectedPremium.value = item.selectedPremium;
                    }
                });
                
            }

        });

        const BuyNow = async () => {
            console.log('buy')
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.9)',
            })
            await captcha?.recaptchaLoaded();
            // execute reCaptcha
            const token = await captcha?.executeRecaptcha("login");
            // console.log(token);

            // eslint-disable-next-line
            const map_id: any = window.localStorage.getItem('map_id');
            const payload = {
                health_lead_map_id: map_id,
                selected_premium: selectedPremium.value,
                policy_id: quote.value.id
            }
            if (token) {
                await Quotes.buyNow(payload);
            }

            loading.close()


            const route = router.resolve({ name: 'CheckoutDetails' })
            window.location.href = route.href;
        }

        return {
            premiums,
            selectedPremium,
            changeCopay,
            assetDomain,
            moreDetails,
            domain,
            BuyNow,
            url
        }
    }
});
