import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vechile-details-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_checkbox = _resolveComponent("h-checkbox")!
  const _component_h_proceed = _resolveComponent("h-proceed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.declarations, (declaration, index) => {
      return (_openBlock(), _createBlock(_component_h_checkbox, {
        key: index,
        label: _ctx.lang == 'en' ? declaration.question : declaration.question_ar,
        modelValue: declaration.answer,
        "onUpdate:modelValue": ($event: any) => ((declaration.answer) = $event),
        member: declaration.members,
        "onUpdate:member": ($event: any) => ((declaration.members) = $event),
        isRequired: "",
        isDeclaration: "",
        id: `declaration_${declaration.id}`,
        onSetValue: ($event: any) => (declaration.answer = !declaration.answer)
      }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "member", "onUpdate:member", "id", "onSetValue"]))
    }), 128)),
    _createVNode(_component_h_proceed, {
      onHandleClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.healthDeclarations.click(_ctx.formPersonalDetails))),
      fullscreenLoading: _ctx.loading
    }, null, 8, ["fullscreenLoading"])
  ]))
}