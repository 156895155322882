/* eslint-disable */
import { store } from "@/store";
import moment from "moment";
import ar from "@/core/plugins/i18n/ar.js"
import en from "@/core/plugins/i18n/en.js"
const language = window.localStorage.getItem('language') || 'en' 
class Validations {
    public static email_taken = false;
    public static phone_taken = false;

    public static customer() {
        return store.state.personals.customer
    }
    
    public static translate(value) {
        const lang = window.localStorage.getItem('language');
        console.log(lang)
        if(lang == 'ar'){
            return ar[value];
        }
        else{
            return en[value];
        }

    }
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            
            callback(new Error(Validations.translate('validation_email_1')));
        } else if(!re.test(String(value).toLowerCase())) {
            callback(new Error(Validations.translate('validation_email_2')));
        } else if(Validations.email_taken) {
            callback(new Error(Validations.translate('validation_email_3')))
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_names')));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_company_name')));
        } else {
            callback();
        }
    }

    public static number_members(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_number_members_1')));
        } else if(value < 1) {
            callback(new Error(Validations.translate('validation_number_members_2')))
        } else {
            callback();
        }
    }

    public static member_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_member_name')));
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_phone_number_1')));
        } else if (value.length < 8) {
            callback(new Error(Validations.translate('validation_phone_number_3')));
        }else if(Validations.phone_taken) {
            callback(new Error(Validations.translate('validation_phone_number_2')))
        } else {
            callback();
        }
    }

    public static insured_type(rule: any, value: any, callback: any) {
    if(!value) {
            callback(new Error(Validations.translate('validation_insured_type')));
        } else {
            callback();
        }
    }

    public static emirate(rule: any, value: any, callback: any) {
        if(!value && value != 0){
            callback(new Error(Validations.translate('validation_emirate')));
        }else {
            callback();
        }  
    }

    public static dob(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error(Validations.translate('validation_dob_1')));
        } else if(moment().diff(value, 'days') < 1) {
            callback(new Error(Validations.translate('validation_dob_2')))
        } else {
            callback();
        }
    }

    public static insurance_date(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error(Validations.translate('validation_insurance_date')));
        } else {
            callback();
        }
    }

    public static day(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_day')));
        } else {
            callback();
        }
    }

    public static month(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_month')));
        } else {
            callback();
        }
    }

    public static year(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_year')));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_nationality')));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_gender')));
        } else {
            callback();
        }
    }

    public static relationship(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(Validations.translate('validation_relationship')));
        } else {
            callback();
        }
    }

    public static no_of_members(rule: any, value: any, callback: any) {
        const re = /^([0-9])$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد عدد الأعضاء' : 'Please select No of Members'))
        } else {
            callback();
        }
    }

    public static company_names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسم الشركة' : 'Please enter company name'))
        } else {
            callback();
        }
    }

    public static group_phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم هاتف مسؤول الاتصال' : 'Please enter contact person phone number'))
        } else if (value.length < 8) {
            callback(new Error(Validations.translate('validation_phone_number_3')));
        }else if(Validations.phone_taken) {
            callback(new Error(Validations.translate('validation_phone_number_2')))
        } else {
            callback();
        }
    }
}

export default Validations;
