import { MutationTree } from 'vuex';

import { MemberCount } from '@/core/types';

import { State } from './state';
import { MemberCountMutationTypes } from '@/core/enum/GroupHealth/MemberCount/Mutations';

export type Mutations<S = State> = {
  [MemberCountMutationTypes.SET_GROUP_HEALTH_MEMBER_COUNT](state: S, payload: Array<MemberCount>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MemberCountMutationTypes.SET_GROUP_HEALTH_MEMBER_COUNT](state: State, member_count: Array<MemberCount>) {
    state.member_count_list = member_count;
  },
};
