/* eslint-disable */
import { Health, Result, Generics, GroupHealth } from '@/core/types';

export type State = {
  customer: Health;
  result: Result;
  members: Array<Generics>;
  loading: boolean;
  ages: Array<number>,
  existingLeads: Array<any>
  selectedLeads: Array<number>
  insured_type_disabled: boolean
  exist_click: boolean,
  mainLoader:boolean,
  customerGroupHealth:GroupHealth,
  loadingGroupHealth:boolean,
}

export const state: State = {
  customer: {
    id: null,
    lang: window.localStorage.getItem('language'),
    name: null,
    email: null,
    contact_person: null,
    phone_number: null,
    phone_country_code: "+971",
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 1,
    is_salary: true,
    expected_insurance_start_date: null,
    expected_premium:null,
    customer_source: null,
    is_health: 1,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    age: 0,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    company_name: null,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    is_company: false,
    deleted_members:[],
    members: [{
      id: null,
      lead_id: null,
      member_name: null,
      relationship: null,
      gender: null,
      age: 0,
      dob: null,
      nationality_id: null,
      is_salary: true,
      declaration_answers: [],
      is_married: false,
      is_editable: true,
      is_temp:0,
      temp_record:0
    }],
    categories: [{
      id: null,
      name: null,
      groups: [{
        id: null,
        age_group: null,
        plan: null,
        group_count: 1
      }]
    }],
    query: {},
    is_declaration_submitted: false,
    is_web: true,
    session_id:null,
    update_insurance_type:false,
    is_investor: false,
  } as Health,
  
  existingLeads: [] as Array<any>,
  selectedLeads: [] as Array<number>,

  result: {} as Result,
  members: [] as Array<Generics>,
  ages: [] as Array<number>,
  loading: false,
  insured_type_disabled: false,
  exist_click: false,
  mainLoader:false,

  customerGroupHealth: {
    uuid: null,
    id: null,
    lang: window.localStorage.getItem('language'),
    name: null,
    email: null,
    contact_person: null,
    phone_number: null,
    phone_country_code: "+971",
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 1,
    is_salary: true,
    expected_premium:null,
    customer_source: null,
    is_health: 1,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    age: 0,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    is_company: false,
    query: {},
    is_web: true,
    session_id:null,
    update_insurance_type:false,
    is_investor: false,
    no_of_members: 0,
    members:[],
    categories: [],
    is_renewal: false,
    cid:null,

  } as GroupHealth,
  loadingGroupHealth:false,

};

