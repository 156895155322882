
import { defineComponent, ref } from "vue";
import { genders } from "@/core/utils";
const language = window.localStorage.getItem('language');

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Gender",
    },
    formProp: {
      type: String,
      required: true,
      default: "gender",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: language == 'ar' ? 'تحديد' : 'Select',
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {


    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const gender = ref()
    const focus = () => {
      setTimeout(() => {
        gender.value.focus()
      }, 500);
    }

    return {
      gender,
      focus,
      updateValue,
      genders,
    };
  },
});
