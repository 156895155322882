import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { MemberCount } from '@/core/types';

import { State } from './state';

export type Getters = {
  getMemberCount(state: State): Array<MemberCount> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getMemberCount: (state) => state.member_count_list,
};
