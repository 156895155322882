
import { computed, defineComponent, onMounted, ref, inject } from "vue";
import Insurances from "@/core/services/Insurances";
import { store } from "@/store";
import router from "@/router";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Nationality",
    },
    formProp: {
      type: String,
      required: true,
      default: "nationality",
    },
    modelValue: {
      type: Number,
      required: true,
      default: 0,
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {
    const isMobile = inject('isMobile')

    onMounted(async () => {
      await Insurances.getInsuredTypes();
    });

    const lang = computed(() => {
      const lang = window.localStorage.getItem("language");
      if(lang)
        return lang;

      return 'en';
    });


    const insurances = computed(() => {
      const insuranceTypes = store.state.insurances.insuredTypes;
      let existLeads = store.state.personals.selectedLeads
      
      const exist = insuranceTypes.find(x => x.value === 0)
      
      if(!exist) insuranceTypes.unshift({ value: 0, label: lang.value == 'en' ? 'Select' : 'تحديد' })

      if(store.state.personals.exist_click) {
        existLeads = existLeads.filter(x => x !== _.modelValue)
        
        if(existLeads.length > 0) {
          return insuranceTypes.filter((x:any) => !existLeads.includes(x.value))
        }
      }
      
      
      return insuranceTypes;
    });

    const updateValue = (event) => {
      const language = window.localStorage.getItem("language");
      // router.push({ query: { lang: language, insurance_type: event } });
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const name = ref()
    const focus = () => {
      console.log('sdff')
      setTimeout(() => {
        name.value.focus()
      }, 500);
    }

    return {
      updateValue,
      insurances,
      name,
      focus,
      isMobile
    };
  },
});
