import { GetterTree } from 'vuex';

import { RootState } from '@/store';

// import { Generics } from '@/core/types';

import { State } from './state';

export type Getters = {
  getQuotes(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getQuotes: (state) =>  {
    const quotes = state.quotes

    const data: any[] = [];
    for (const x of quotes){
        // console.log('x',x['insurance_provider_id'])

        const obj = data.find(o => o['insurance_provider_id'] === x['insurance_provider_id']);
        
        if(x['memberPremiums'] && x['memberPremiums'].length > 0)
          x['selected_premium'] = x['memberPremiums'][0];

        if(obj){
          const index = data.indexOf(obj);
          data[index].children.push(x)
        }
        else{
          x['children'] = [];
          x['expand'] = false;
          data.push(x);
        }
    }

    return data

  },

  getCompareBenefits: (state) => {
    // const data = JSON.parse(window.localStorage.getItem("compare_data") ?? '');
    // if(data && data.length > 0){
    //   // let benefits = [];
    //   // data.forEach(row => {
    //   //     let val = row.more_details.filter((e) => e)
    //   // });
    // }

    return  state.benefit_master.map((x)=>{
      return {
        id:x.id,
        title:x.title
      }
    })
  },

  getCompareBenefitsValue: (state) => {
    const data = JSON.parse(window.localStorage.getItem("compare_data") ?? '');
    const lang = window.localStorage.getItem("language");
    if(data && data.length > 0){
      const bValues = state.benefit_master.map(x => {
      const benefits: any[] = [];
        const notCovered = lang == 'ar' ? 'Not Covered' : 'Not Covered'
        data.forEach(row => {
            const val = row.more_details.filter((e) => e.benefit_id == x.id );
            if(val.length > 0){
              const value = lang == 'ar' ?  val[0].value_ar : val[0].value;

              benefits.push(value ?? notCovered);
            }
            else 
              benefits.push(notCovered);
        });

        if(lang == 'ar'){
          x.title = x.title_ar;
        }

        x.benefits = benefits;

        return x;
      });

      return bValues;
    }

    return [];

  }
};
