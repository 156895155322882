  export enum PersonalActionTypes {
    POST_PERSONAL_DETAILS = 'POST_PERSONAL_DETAILS',
    GET_LATEST_LEADS = 'GET_LATEST_LEADS',
    SET_CUSTOMER_LEADS = 'SET_CUSTOMER_LEADS',
    GET_PERSONAL_DETAILS = 'GET_PERSONAL_DETAILS',

    // GROUP_HEALTH
    POST_GROUP_HEALTH_PERSONAL_DETAILS = 'POST_GROUP_HEALTH_PERSONAL_DETAILS',
    GET_GROUP_HEALTH_CUSTOMER = 'GET_GROUP_HEALTH_CUSTOMER',
  }
  