
import { defineComponent, computed } from "vue";
import { store } from "@/store";

export default defineComponent({
  setup() {
    const notValidDeclaration = computed(() => {
      return store.getters.getNotValidDeclaration(
        store.state.personals.customer.insured_type_id
      );
    });

    const language = computed(() => {
      return store.state.lang.lang;
    });
    const homePage =
      process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value;

    return {
      notValidDeclaration,
      homePage,
    };
  },
});
