
/* eslint-disable */
import { defineComponent, reactive, toRef, ref, computed, watchEffect, getCurrentInstance } from "vue";
import { Health, Member } from "@/core/types";
import Validations from "@/core/validations";
import { FormInstance } from "element-plus";
import { northern_emirate } from "@/core/utils";
import { store } from "@/store";
import { focus, dates as dDate } from "@/store/stateless"
import moment from "moment";

const __default__ = defineComponent({
  name: 'membersComponent',
  props: {
    item: {
      type: Object,
      required: true,
      default: {} as Member,
    },
    counting: {
      type: Number,
      required: true,
      default: 1
    },
    members: {
      type: Array,
      required: true,
      default: []
    },
    insurance_type: {
      type: Number,
      required: true,
      default: 1
    },
    customer: {
      type: Object,
      required: true,
      default: {} as Health,
    },
  },

  setup(_) {
    const item = toRef(_, 'item');

    const formMemberDetails = ref<FormInstance>();

    const dob = (rule: any, value: any, callback: any) => {
      if (value === 'Invalid date' || !value) {
        callback(new Error(`Please enter date of birth`));
      } else if (moment().diff(value, 'days') < 1) {
        callback(new Error('You still don\'t exist'))
      } else {
        callback();
      }
    }
    const rules = reactive({
      member_name: [{ validator: Validations.member_name, trigger: ["blur", "change"] }],
      dob: [{ validator: dob, trigger: ["blur", "change"] }],
      dob_day: [{ validator: Validations.day, trigger: ["blur", "change"] }],
      dob_month: [{ validator: Validations.month, trigger: ["blur", "change"] }],
      dob_year: [{ validator: Validations.year, trigger: ["blur", "change"] }],
      nationality_id: [{ validator: Validations.nationality, trigger: ["blur", "change"] }],
      gender: [{ validator: Validations.gender, trigger: ["blur", "change"] }],
      relationship: [{ validator: Validations.relationship, trigger: ["blur", "change"] }],
    });

    const relationship = ref();

    const buttonDisabled = computed(() => {
      if (item.value.member_name && item.value.dob_day && item.value.dob_month && item.value.dob_year && item.value.dob !== 'Invalid date' && item.value.gender && item.value.nationality_id && item.value.relationship) return false
      return true
    })

    const buttonCss = computed(() => {
      return {
        background: buttonDisabled.value ? '#f9f9f9' : '#fff',
        cursor: buttonDisabled.value ? 'unset' : 'pointer'
      }
    })

    const hasEditable = computed(() => {
      return store.getters.getHasEditable
    })

    const inst = getCurrentInstance()

    const dobRef = computed(() => {
      if (!item.value.dob_day) return 'dob_day'
      if (!item.value.dob_month) return 'dob_month'
      if (!item.value.dob_year) return 'dob_year'

      return 'dob'
    })

    const updateGender = (member) => {
      if (member.relationship === 'Son' || member.relationship === 'Brother' || member.relationship === 'Father' || member.relationship === 'Father-in-law')
        member.gender = 'MALE';
      else if (member.relationship === 'ابن' || member.relationship === 'أخ' || member.relationship === 'أب' || member.relationship === 'حَمُو')
        member.gender = 'MALE';
      else if (member.relationship === 'Daughter' || member.relationship === 'Sister' || member.relationship === 'Mother' || member.relationship === 'Mother-in-law')
        member.gender = 'FEMALE';
      else if (member.relationship === 'ابنة' || member.relationship === 'أخت' || member.relationship === 'أم' || member.relationship === 'حَمَاة')
        member.gender = 'FEMALE';
      else
        member.gender = null;

      UpdateIsMarried(member)

    }
    const UpdateIsMarried = (member) => {
      member.is_married = false
      if (member.gender === 'MALE') member.is_married = false

      if (member.relationship === 'Spouse' && member.gender === 'FEMALE') {
        member.is_married = true
      }
      else
        member.is_married = false
    }

    const dates = inst?.appContext.config.globalProperties.dates;
    const addNewMember = (formEl: FormInstance, members: Array<Member>, member: Member) => {
      console.log(formEl)
      if (!formEl) {
        // members.push({
        //   id: null,
        //   lead_id: 0,
        //   member_name: null,
        //   relationship: null,
        //   gender: null,
        //   dob: null,
        //   age: 0,
        //   nationality_id: null,
        //   is_salary: true,
        //   declaration_answers: [],
        //   is_married: false,
        //   is_editable: true,
        //   is_temp: 0
        // })
        return;
      }
      formEl.validate(async (valid, fields: any) => {
        if (valid) {
          member.age = dates.getAge(member.dob);
          member.is_editable = false;
          // members.push({
          //   id: null,
          //   lead_id: 0,
          //   member_name: null,
          //   relationship: null,
          //   gender: null,
          //   dob: null,
          //   age: 0,
          //   nationality_id: null,
          //   is_salary: true,
          //   declaration_answers: [],
          //   is_married: false,
          //   is_editable: true,
          //   is_temp: 0
          // })
          const member_name: any = inst?.refs.member_name
          if (member_name !== undefined) {
            member_name.focus()
          }
        } else {
          focus.member = ''
          formEl.scrollToField(Object.keys(fields)[0] + "_label")
          focus.personal = ''
          focus.member = Object.keys(fields)[0]

          if (focus.member !== '') {
            const toFocus: any = inst?.refs[focus.member]
            if (toFocus !== undefined) {
              toFocus.focus()
            }
          }
        }
      })
    }

    const customer = computed(() => {
      return store.state.personals.customer;
    });


    const mDate = () => {
      if(customer.value.insured_type_id == 2){
        return dDate.min;
      }
      else
        return dDate.today;
    }
    return {
      addNewMember,
      UpdateIsMarried,
      updateGender,
      dobRef,
      focus,
      dDate,
      hasEditable,
      buttonCss,
      buttonDisabled,
      relationship,
      item,
      rules,
      formMemberDetails,
      northern_emirate,
      mDate
    }
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b7757388": (_ctx.buttonCss.background),
  "5891bb64": (_ctx.buttonCss.cursor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__