/* eslint-disable */
import { App } from "vue";
import hd from "@/core/services/Declarations";
import { store } from "@/store";
import { healthDeclarations as declareHealth } from '@/core/types';
import { isCalled } from '@/store/stateless';

export const healthDeclarations = (app: App<Element>) => {
    app.config.globalProperties.healthDeclarations = {
        async click() {
                    // hd.setLoading(true);
                    isCalled.mainLoader = true
                    const declarations = store.state.declarations.declarations;
                    console.log('declarations',declarations);
                    const members = store.state.personals.members;
                    const result = store.state.personals.result;
                    let isPresent: Boolean = false;
                    const countPresent = declarations.filter(x => x.answer);
                    //  console.log(countPresent)
                    if(countPresent.length > 0)
                        isPresent = true;
                        
                    const lead_information = members.map((x:any) => {
                        const declaration_answers = declarations.map(y => {
                            if(members.length > 1) {
                                return {
                                    id: y.id,
                                    answer: y.members.includes(x.value) && y.answer ? 'YES' : 'NO',
                                    question: y.question
                                }
                            }

                            return {
                                id: y.id,
                                answer: y.answer ? 'YES' : 'NO',
                                question: y.question
                            }
                        })

                        return {
                            id: x.value,
                            declaration_answers
                        }
                    })

                    const data  = <declareHealth>{
                        health_lead_map_id: result.health_lead_map_id,
                        lead_information,
                        is_present:isPresent
                    }

                    await hd.postDeclarations(data);
                    // hd.setLoading(false);

                    // isCalled.mainLoader = false

            }
        }

    }