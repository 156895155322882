/* eslint-disable */
import { App, getCurrentInstance } from "vue";
import { FormInstance } from "element-plus";
import { GroupHealth, Member } from "@/core/types";
import pd from "@/core/services/PersonalDetails";
import { store } from "@/store";
import { focus, thankYouPopup, insuredFamilyValue, insuredParentValue } from "@/store/stateless"
import router from "@/router";
import Validations from "@/core/validations";

export const personalDetailsGroup = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.personalDetailsGroup = {
        async click(formEl: FormInstance, inst) {
            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                if (valid) {

                    pd.setLoading(true);

                    const customer = store.state.personals.customerGroupHealth

                    customer.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : '';
                    customer.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : '';
                    customer.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : '';
                    customer.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : '';
                    // customer.insurance_type = router.currentRoute.value.query?.insurance_type ? parseInt(router.currentRoute.value.query.insurance_type.toString()) : 1;
                    const sessionId = window.sessionStorage.getItem('session_id');
     
                    if(sessionId)
                        customer.session_id = sessionId;

                    const isRenewal = window.sessionStorage.getItem('renewal');
                    customer.is_renewal = false;

                    if(isRenewal){
                        customer.is_renewal = true;
                        const cid = window.sessionStorage.getItem('cid');
                        if(cid)
                            customer.cid = cid;
                    }

                    // console.log('customer in api payload',customer);

                    // const customer = store.state.personals.customer
                    await pd.postGroupHealth(customer);
                    pd.setLoading(false);
                } else {
                    // this.scrollToError(Object.keys(fields)[0]);
                    focus.personal = ''
                    formEl.scrollToField(Object.keys(fields)[0] + "_label")
                    focus.personal = Object.keys(fields)[0]
                    focus.member = ''

                    // const inst = getCurrentInstance()
                    console.log(inst)
                    const toFocus: any = inst?.refs[Object.keys(fields)[0]]
                    console.log(toFocus,Object.keys(fields)[0])
                    if (toFocus !== undefined) {
                        toFocus.focus()
                    }
                }
            })
        },

        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        },
        addNewMember(formEl: FormInstance, leads: Array<GroupHealth>, health: GroupHealth) {
            if (health.email && health.name && health.contact_person && health.phone_number && health.no_of_members) {
                if (!formEl) return;
                formEl.validate(async (valid) => {
                    if (valid) {
                        // leads.push({
                        //     id: null,
                        //     lang: "en",
                        //     email: null,
                        //     name: null,
                        //     contact_person: null,
                        //     phone_number: null,
                        //     phone_country_code: "+971",
                        //     no_of_members: null,
                        //     customer_source: null,
                        //     utm_source: null,
                        //     utm_medium: null,
                        //     utm_campaign: null,
                        //     utm_content: null,
                        //     uuid:null,
                        // })
                    }
                })
            }
        },

        scrollToError(e:string) {
            if(e == 'leads.pet_age') {
                setTimeout(() => {
                    window.scroll(0, 450)
                }, 500);
            } 
            else if(e == 'customer_name') {
                setTimeout(() => {
                    window.scroll(0, 550)
                }, 500);
            } 
            else if(e == 'customer_phone') {
                setTimeout(() => {
                    window.scroll(0, 650)
                }, 500);
            } 
            else if(e == 'customer_email') {
                setTimeout(() => {
                    window.scroll(0, 700)
                }, 500);
            } 
            else {
                setTimeout(() => {
                    window.scroll(0, 0)
                }, 500);
            }
        }
    }
}