
import { computed, defineComponent, onMounted, ref } from "vue";
import Relationships from "@/core/services/Relationships";
import { store } from "@/store";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Relationship",
    },
    formProp: {
      type: String,
      required: true,
      default: "relationship",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {

    onMounted(async() => {
      await load();
    });

    const load = async() => {
      await Relationships.getInsuredTypes();
    }

    const relationships = computed(() => {
      return store.getters.getRelationships(store.state.personals.customer.insured_type_id)
    });

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const relationship = ref()
    const focus = () => {
      setTimeout(() => {
        relationship.value.focus()
      }, 500);
    }

    return {
      relationship,
      focus,
      load,
      updateValue,
      relationships,
    };
  },
});
