import { Generics } from '@/core/types';


export type State = {
  quotes: Array<Generics>;
  informations: Array<Generics>;
  interested_policy:any;
  vat:number;
  compare_list:Array<any>,
  benefit_master:Array<any>,
  copay_selection:Array<any>
}

export const state: State = {
  quotes: [] as Array<Generics>,
  informations: [] as Array<Generics>,
  interested_policy:null,
  vat:0,
  compare_list:[],
  benefit_master:[],
  copay_selection:[]
};