
import { store } from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const lang = computed(() => {
      const lang = window.localStorage.getItem("language");
      if(lang)
        return lang;

      return 'en';
    });
    const declarations = computed(() => {
        return store.state.declarations.declarations;
    })

    const loading = computed(() => {
        return store.state.declarations.loading;
    })

    return {
        declarations,
        loading,
        lang
    }
  },
});
