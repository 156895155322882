import { MutationTree } from 'vuex';

import { Generics } from '@/core/types';

import { State } from './state';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';

export type Mutations<S = State> = {
  [QuoteMutationTypes.SET_QUOTES](state: S, payload: Array<Generics>): void;
  [QuoteMutationTypes.SET_INFORMATIONS](state: S, payload: Array<Generics>): void;
  [QuoteMutationTypes.SET_INTERESTED_POLICY](state: S, payload: any): void;
  [QuoteMutationTypes.SET_VAT](state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [QuoteMutationTypes.SET_QUOTES](state: State, quotes: Array<Generics>) {
    state.quotes = quotes;
  },
  [QuoteMutationTypes.SET_INFORMATIONS](state: State, informations: Array<Generics>) {
    state.informations = informations;
  },
  [QuoteMutationTypes.SET_INTERESTED_POLICY](state: State, policy: any) {
    state.interested_policy = policy;
  },
  [QuoteMutationTypes.SET_VAT](state: State, vat: number) {
    state.vat = vat;
  },

  [QuoteMutationTypes.SET_BENEFIT_MASTER](state: State, payload:any) {
    state.benefit_master = payload;
  },
};
