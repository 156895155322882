
import { computed, defineComponent, onMounted, ref, inject } from "vue";
import Countries from "@/core/services/Countries";
import { store } from "@/store";
const language = window.localStorage.getItem('language');

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Nationality",
    },
    formProp: {
      type: String,
      required: true,
      default: "nationality",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: language == 'ar' ? 'تحديد' : 'Select',
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {

    const isMobile = inject('isMobile')
    const nationality_ids = ref('')
    const nationality_search = ref('')

    onMounted(async () => {
      await Countries.getCountries();

      if(isMobile) {
          const nationality_teleport = document.getElementsByClassName('nationality_teleport')[0]
          nationality_ids.value = nationality_teleport.id

      }
    });


    
    const countries = computed(() => {
      return store.state.countries.countries;
    });

    const filtered_nationality = computed(() => {
            if(countries.value) {
                return countries.value.filter(obj => 
                obj.label.toString().toLowerCase().includes(nationality_search.value.toLowerCase())
                );
            }
            return []
    })

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const nationality = ref()
    const focus = () => {
      setTimeout(() => {
        nationality.value.focus()
      }, 500);
    }

    return {
      nationality,
      focus,
      updateValue,
      countries,
      nationality_ids,
      nationality_search,
      isMobile,
      filtered_nationality
    };
  },
});
