
import { defineComponent, computed, reactive, getCurrentInstance, watchEffect } from "vue";
import { store } from "@/store";
import { emirates } from "@/core/utils";
import { formEmployeeDetails, dates } from "@/store/stateless";
import Validations from "@/core/validations";
import { focus } from "@/store/stateless"

export default defineComponent({
  name: 'employeeComponent',
  setup() {
    const customer = computed(() => {
      return store.state.personals.customer;
    });
    
    const loading = computed(() => {
      return store.state.personals.loading;
    });

    const rules = reactive({
      name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      company_name: [{ validator: Validations.company_name, trigger: ["blur", "change"] }],
      phone_number: [
        { validator: Validations.phone_number, trigger: ["blur", "change"] },
      ],
      insured_city: [
        { validator: Validations.emirate, trigger: ["blur", "change"] },
      ],
      expected_insurance_start_date: [
        { validator: Validations.insurance_date, trigger: ["blur", "change"] },
      ],
      expected_insurance_start_day: [
        { validator: Validations.day, trigger: ["blur", "change"] },
      ],
      expected_insurance_start_month: [
        { validator: Validations.month, trigger: ["blur", "change"] },
      ],
      expected_insurance_start_year: [
        { validator: Validations.year, trigger: ["blur", "change"] },
      ],
      email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
      number_members: [{ validator: Validations.number_members, trigger: ["blur", "change"] }],
    });


    const inst = getCurrentInstance()
    watchEffect(() => {
      if(focus.personal !== '') {
        // eslint-disable-next-line
          const toFocus:any = inst?.refs[focus.personal]
          if(toFocus !== undefined) {
            toFocus.focus()
          }
      }
    })

    return {
      dates,
      focus,
      emirates,
      rules,
      customer,
      loading,
      formEmployeeDetails,
    };
  },
});
