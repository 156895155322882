
import { defineComponent, onMounted } from 'vue'
import Declarations from '@/core/services/Declarations';
import { ElLoading } from 'element-plus'
import { isCalled } from '@/store/stateless';

export default defineComponent({
    setup() {
        onMounted(async () => {
            
            // const loading = ElLoading.service({
            //     lock: true,
            //     text: 'Loading',
            //     background: 'rgba(0, 0, 0, 0.9)',
            // })
            isCalled.mainLoader = true
            await Declarations.getDeclarations();

            // loading.close()
            isCalled.mainLoader = false


        })
    },
})
