const en = {
  test: "الإنجليزية",
  personal: "التفاصيل",
  details: "الشخصية",
  declaration: "الإقرار",
  compare: "قارن",
  quotes: "الأسعار عروض",
  purchase: "الشراء",
  insurance: "التأمين",
  personal_details: "التفاصيل الشخصية",
  will_take: "تستغرق العملية أقل من 1 دقيقة",
  full_name: "الاسم الكامل", 
  full_name_tooltip:  "الاسم الكامل", 
  phone_number: "رقم الهاتف",
  insured_type: "نوع المؤمن عليه",
  city: "التأشيرة صادرة في (الإمارات)",
  city_tooltip: "المدينة ",
  date_of_birth: "تاريخ الولادة",
  date_of_birth_tooltip: "تاريخ الولادة",
  day: "اليوم",
  month: "الشهر",
  year: "السنة",
  nationality: "الجنسية",
  yes: "نعم",
  no: "لا",
  salary: "هل راتبك أكثر من {salary} درهم شهريًا؟",
  expected_insurance: "التاريخ المتوقع لبدء التأمين",
  expected_insurance_tooltip: "التاريخ المتوقع لبدء التأمين",
  email_address: "البريد الإلكتروني",
  email_address_placeholder: "عنوان البريد الإلكتروني",
  email_address_tooltip: "عنوان البريد الإلكتروني",
  gender: 'الجنس',
  member_date_of_birth: "الرجاء تحديد تاريخ ميلاد العضو",
  relationship: 'الصلة',
  member_full_name: "الاسم الكامل", 
  member_details: 'أدخل تفاصيل العضو',
  company_name: 'اسم الشركة',
  employee_full_name: "الاسم الكامل", 
  company_contact_number: 'رقم الاتصال',
  company_email_address: 'عنوان البريد الإلكتروني للشركة',
  company_no_members: 'عدد الأعضاء',
  add_member: 'إضافة عضو',
  add_another_member: 'إضافة عضو آخر',
  save_edited_member: 'حفظ العضو',
  member: 'العضو',
  edit_details: 'تعديل التفاصيل',
  delete: 'الحذف',
  name: 'الاسم',
  salary_more_than: 'الراتب > 4000 درهم',
  thank_you: 'شكرًا لك',
  some_extra_information: 'بعض المعلومات الإضافية مطلوبة',
  declaration_que_ans: 'تبعًا لإجابات الإقرار الخاصة بك، سنطلب معلومات إضافية من أجل استحداث عروض الأسعار. سنتصل بك قريبًا لإنهاء العملية.',
  no_declaration_que_ans: 'سوف نطلب معلومات إضافية من أجل استحداث عروض الأسعار. سنتصل بك قريبًا لإنهاء العملية.',
  alternatively_call_us: 'أو اتصل بنا على رقم الهاتف: <a href="tel:800765429" dir="ltr">800 765 429</a> للمساعدة الفورية. ',
  is_married: 'متزوج؟',
  is_self: 'هل تحتاج إلى التأمين لنفسك؟',
  all: 'الكل',
  basic: 'الأساسي',
  enhanced: 'المتقدم',
  
  get_insured: "احصل على تأمين",
  car_insurance: "تأمين السيارة",
  health_insurance: "التأمين الصحي",
  home_insurance: "تأمين المنزل",
  bike_insurance: "تأمين الدراجة النارية",
  other_insurance: "تأمينات أخرى",
  renew_a_policy: "تجديد التأمين",
  company: "الشركة",
  about: "نبذة عنّا",
  insurance_partners: "شركاء التأمين",
  hr_solutions: "حلول الموارد البشرية",
  blog: "المدونة",
  quick_links: "روابط سريعة",
  privacy_page: "صفحة الخصوصية",
  tac: "الأحكام والشروط",
  ami: "تأمين المنارة",
  edarat: "E-DARAT",
  support: "الدعم",
  help_center: "مركز المساعدة",
  contact: "اتصل بنا",
  cancellation: "إلغاء التأمين",
  calculator: "الحاسبات",
  car_loan: "قرض السيارة",
  mortgage: "القرض العقاري",
  insurance_calc: "حاسبة التأمين",
  mail: 'البريد الإلكتروني',
  head_office: 'المكتب الرئيسي',
  need_assistance: 'طلب المساعدة',
  call_us: 'أو اتصل بنا على',
  support_center: 'طلب المساعدة',
  corporate: 'الشركات',
  solution: 'حلول',
  address: '1406، داماك سمارت هايتس، برشاء هايتس، دبي، الإمارات.',
  disclaimer: 'إخلاء مسؤولية',
  disclaimer_text: 'شركة بريميوم تشويس ماركتينج مكتب 1406 داماك سمارت هايتس، برشا هايتس، دبي، الإمارات هي المالكة للاسم التجاري "BuyAnyInsurance.com/ae". التأمين مُقدّم من قبل شركة نورث ستار لخدمات التأمين برخصة رقم 227 من هيئة التأمين  ',
  company_of: 'إحدى شركات ',
  proceed: "أكمل",
  wait: "الرجاء الانتظار",
  corporate_solution: 'حلول الشركات',
  thank_you_msg : 'إذا كنت بحاجة إلى استعراض الأسعار، الرجاء النقر على إلغاء وإدخال تفاصيل العضو على صفحة التفاصيل الشخصية. أما إذا كنت ترغب بإعادة الاتصال، الرجاء النقر على زر طلب إعادة الاتصال.',
  cancel : 'إلغاء',
  request_call_back:'طلب إعادة الاتصال',
  callback_thank_you_msg: 'شكرًا لك على تقديم الطلب. سوف يتصل بك وكلاؤنا قريبًا!',
  no_quotes_manual_quote_title: 'للحصول على عرض أسعار يدوي، يرجى الاتصال بنا على 800765429 أو ',
  go_back: 'العودة إلى الصفحة الرئيسية',
  AED: "درهم", 
  annual_limit: 'الحد السنوي',
  scope_coverage: 'نطاق التغطية',
  network_list: 'قائمة الشبكة',
  maternity_coverage: 'تغطية الأمومة',
  dental:'الأسنان',
  optical:'البصر',
  deductibles: 'الاقتطاع',
  copay:'الدفع المشترك',
  total_premium:'إجمالي الأقساط',
  buy_now:'اشتر الآن',
  member_premium:'قسط العضو',
  add_compare:'أضف للمقارنة',
  show_more:'إظهار المزيد',
  show_less:'إظهار أقل',
  price_vat: 'الأسعار التي تراها هنا لا تشمل {vat}% ضريبة القيمة المضافة',
  quotes_count: 'لقد وجدنا لك {count} عروض أسعار للتأمين الصحي',
  show_all_btn: 'إظهار الكل ({count}) المتوفر في {policy}',
  show_less_btn: 'إظهار أقل المتوفر في {policy}',
  we_cannot_process: 'لا يمكننا معالجة طلبك من خلال الإنترنت. سيتصل بك فريقنا قريبًا',
  no_quotes_point_one: 'تندرج متطلباتك تحت مجموعة محددة ترغب شركات التأمين في معرفة المزيد عنها',
  secure_checkout: "الخروج الآمن",
  show_full_summary: 'عرض الملخص الكامل',
  full_summary: ' الملخص الكامل',
  hide_full_summary: 'إخفاء الملخص الكامل',
  summary: "الملخص",
  premium: "القسط",
  vat: "ضريبة القيمة المضافة",
  vat_t: "ضريبة القيمة المضافة {vat} %",
  total_amount: "المبلغ الإجمالي",
  basmah: 'بسمة',
  agree: 'أوافق على',
  download: 'تنزيل',
  payable_amount: 'المبلغ المستحق الدفع',
  no_quotes_found : 'لا يوجد عروض  أسعار !!',
  compare_offer: "قارن العروض",
  compare_plan: 'قارن الخطط',
  back: 'عودة',
  expected_premium : 'القسط المتوقع',
  expected_premium_placeholder : 'أدخل القسط المتوقع',
  premium_statement : 'القسط للإرشاد فقط ويخضع للانتهاكات القسط  ',
  premium_statement1 : 'القسط للإرشاد فقط   ',
  copyrights: 'حقوق الطبع والنشر',
  copyright_text: ' التأمين موضوع طلب. ',
  is_investor: 'هل أنت مستثمر؟',
  select_existing_lead : 'اختر من البيانات المدخلة الموجودة',
  enter_new_details: 'أدخل تفاصيل جديدة',

  validation_email_1: "الرجاء إدخال البريد الإلكتروني",
  validation_email_2: "يجب أن يكون عنوان البريد الإلكتروني صالحًا",
  validation_email_3: "البريد الإلكتروني مستخدم بالفعل",
  validation_names: "الرجاء إدخال اسمك الكامل",
  validation_company_name: "الرجاء إدخال اسم شركتك",
  validation_number_members_1: "الرجاء إدخال عدد الأعضاء",
  validation_number_members_2: "يجب ألا يقل عدد الأعضاء عن أو يساوي",
  validation_member_name: "الرجاء إدخال الاسم الكامل للعضو",
  validation_phone_number_1: "الرجاء إدخال رقم الهاتف",
  validation_phone_number_2: "رقم الهاتف مستخدم من قبل",
  validation_phone_number_3: "Phone number must not be less than 8 digits",
  validation_insured_type: "الرجاء تحديد نوع المؤمن عليه",
  validation_emirate: "الرجاء اختيار المدينة",
  validation_dob_1: "الرجاء إدخال تاريخ الميلاد",
  validation_dob_2: "أنت لا تزال غير موجود",
  validation_insurance_date: "الرجاء تحديد التاريخ الصحيح",
  validation_day: "الرجاء تحديد التاريخ",
  validation_month: "الرجاء تحديد الشهر",
  validation_year: "الرجاء تحديد السنة",
  validation_nationality: "الرجاء تحديد الجنسية",
  validation_gender: "الرجاء تحديد الجنس",
  validation_relationship: "الرجاء تحديد الصلة",
  incl_vat: "( incl. vat)",
  your_cover: 'التغطية',
  faqs: "الأسئلة المتداولة",

  corporate_info: "معلومات الشركة",
  company_email: "البريد الإلكتروني للشركة",
  company_email_placeholder: "عنوان البريد الإلكتروني للشركة",
  company_name_placeholder: "اسم الشركة",
  contact_number: "رقم الاتصال",
  contact_number_placeholder: "رقم الاتصال",
  contact_person: "مسؤول الاتصال ",
  contact_person_placeholder: "مسؤول الاتصال ",
  no_of_members: "عدد الأعضاء/حجم المجموعة",
  no_of_members_placeholder: "عدد الأعضاء/حجم المجموعة",
  thank_you_group: 'شكرًا لك',
  callback_thank_you_msg_group: 'شكرًا لاستفسارك. سيتصل بك فريقنا في أقرب وقت ممكن.',
  go_back_home: 'العودة إلى الصفحة الرئيسية',

};

export default en;
