import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_type_not_employee = _resolveComponent("h-type-not-employee")!
  const _component_h_type_employee = _resolveComponent("h-type-employee")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.customer.insured_type_id !== 4)
      ? (_openBlock(), _createBlock(_component_h_type_not_employee, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.customer.insured_type_id === 4)
      ? (_openBlock(), _createBlock(_component_h_type_employee, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}