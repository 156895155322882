
import { computed, defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const websiteUrl = computed(() => {
            return process.env.VUE_APP_PAYMENT_GATEWAY_URL + '/' + window.localStorage.getItem('language');
        })

        const email = computed(() => {
            return process.env.VUE_APP_MAIL
        })

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        const linkedin = computed(() => {
            return process.env.VUE_APP_LINKEDIN
        })

        const facebook = computed(() => {
            return process.env.VUE_APP_FACEBOOOK
        })

        const instagram = computed(() => {
            return process.env.VUE_APP_INSTAGRAM
        })

        const twitter = computed(() => {
            return process.env.VUE_APP_TWITTER
        })

        return {
            language,
            linkedin,
            facebook,
            instagram,
            twitter,
            email,
            websiteUrl
        }
    },
})
