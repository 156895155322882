import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vechile-details-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_insured_type = _resolveComponent("h-insured-type")!
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formEmployeeDetails",
    model: _ctx.customer,
    "status-icon": "",
    rules: _ctx.rules,
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('employee_full_name'),
          isType: "text",
          formProp: "name",
          modelValue: _ctx.customer.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.name) = $event)),
          maxLength: "62",
          onKey: "return /[a-zA-Z\\s'-]/i.test(event.key)",
          isTooltip: "",
          tooltipMessage: _ctx.$t('full_name_tooltip'),
          ref: "name"
        }, null, 8, ["label", "modelValue", "tooltipMessage"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('company_name'),
          isType: "text",
          formProp: "company_name",
          modelValue: _ctx.customer.company_name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.company_name) = $event)),
          ref: "company_name"
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('company_contact_number'),
          isType: "phone",
          formProp: "phone_number",
          modelValue: _ctx.customer.phone_number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_number) = $event)),
          countryCode: _ctx.customer.phone_country_code,
          "onUpdate:countryCode": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.phone_country_code) = $event)),
          ref: "phone_number"
        }, null, 8, ["label", "modelValue", "countryCode"]),
        _createVNode(_component_h_insured_type, {
          label: _ctx.$t('insured_type'),
          formProp: "insured_type_id",
          modelValue: _ctx.customer.insured_type_id,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.insured_type_id) = $event)),
          ref: "insured_type_id"
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('city'),
          isType: "select",
          formProp: "insured_city",
          modelValue: _ctx.customer.insured_city,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.insured_city) = $event)),
          options: _ctx.emirates,
          isTooltip: "",
          tooltipMessage: _ctx.$t('city_tooltip'),
          ref: "insured_city"
        }, null, 8, ["label", "modelValue", "options", "tooltipMessage"]),
        _createVNode(_component_h_date_v2, {
          isRequired: "",
          "model-value": _ctx.customer.expected_insurance_start_date,
          "onUpdate:model-value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.expected_insurance_start_date) = $event)),
          "is-tooltip": "",
          "tooltip-message": _ctx.$t('expected_insurance_tooltip'),
          "form-prop": "expected_insurance_start_date",
          label: _ctx.$t('expected_insurance'),
          ref: "expected_insurance_start_date",
          min: _ctx.dates.today,
          max: _ctx.dates.expected_date
        }, null, 8, ["model-value", "tooltip-message", "label", "min", "max"]),
        _createVNode(_component_h_input, {
          label: _ctx.$t('company_email_address'),
          isType: "text",
          formProp: "email",
          placeHolder: _ctx.$t('company_email_address'),
          modelValue: _ctx.customer.email,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.email) = $event)),
          maxLength: "62",
          ref: "email"
        }, null, 8, ["label", "placeHolder", "modelValue"]),
        _createVNode(_component_h_input, {
          label: _ctx.$t('company_no_members'),
          isType: "number",
          formProp: "number_members",
          modelValue: _ctx.customer.number_members,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.number_members) = $event)),
          maxLength: "5",
          ref: "number_members"
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_h_proceed, {
          onHandleClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.personalDetails.click(_ctx.formEmployeeDetails))),
          fullscreenLoading: _ctx.loading
        }, null, 8, ["fullscreenLoading"])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}