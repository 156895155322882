import moment from "moment/moment";
import { reactive } from "vue";

const focus = reactive({
  personal: '',
  member: ''
});

const emirates = reactive({
  abhu_dhabi: 0, 
  ajman: 1, 
  dubai: 2, 
  fujairah: 3, 
  ras_al_khaimah: 4, 
  sharjah: 5, 
  umm_al_quwain: 6, 
})

const insurance_for = reactive({
  myself: 1,
  domestic_worker: 2,
  family: 3,
  employee: 4,
  parents: 5
})

const insuredFamilyValue = 3

const insuredParentValue = 5

const insuredDomesticWorkerValue = 2

const screen = reactive({
  width: window.screen.width
})

const thankYouPopup = reactive({
  open : false
})

const dates = reactive({
  min: moment().subtract(17,'y').format('YYYY-MM-DD'),
  today: moment().format('YYYY-MM-DD'),
  expected_date: moment().add(6, 'M').format('YYYY-MM-DD')
})

export { focus, emirates, insurance_for, dates, screen, thankYouPopup, insuredFamilyValue , insuredParentValue, insuredDomesticWorkerValue };