import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { Declaration } from '@/core/types';

import { State } from './state';

export type Getters = {
  getDeclarations(state: State): Array<Declaration> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getDeclarations: (state) => state.declarations,
  getNotValidDeclaration : (state) => (insured_type_id: number) => {
    // const declarations = state.declarations.filter(x => x.answer === true)
    // console.log('length',declarations )
    // if(insured_type_id === 1 && declarations.length > 0) return true
    // if(insured_type_id !== 1 && declarations.length > 0 && declarations.filter(x => x.members.length > 0).length > 0) return true
    // if(declarations.length > 0) return true

    const wdata = window.localStorage.getItem('health_declaration_issue');
    if(wdata && wdata == 'YES')
    {
      console.log('length',wdata )
      return true;
    }


    return false
  },
  getQuotesFoundIssue : (state) => {
    const wdata = window.localStorage.getItem('quotes_not_found');
    if(wdata && wdata == 'YES')
    {
      return true;
    }
    return false
  }
};
