/* eslint-disable */
import { MutationTree } from 'vuex';

import { Health, Result, Generics, GroupHealth } from '@/core/types';

import { State } from './state';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';

export type Mutations<S = State> = {
  [PersonalMutationTypes.SET_CUSTOMER](state: S, payload: Health): void;
  [PersonalMutationTypes.SET_CUSTOMER_AGES](state: S, payload: Array<number>): void;
  [PersonalMutationTypes.SET_CUSTOMER_MEMBERS](state: S, payload: Array<Generics>): void;
  [PersonalMutationTypes.SET_CUSTOMER_RESULT](state: S, payload: Result): void;
  [PersonalMutationTypes.SET_CUSTOMER_LOADING](state: S, payload: boolean): void;
  [PersonalMutationTypes.GET_GROUP_HEALTH_CUSTOMER](state: S, payload: GroupHealth): void;
  [PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER](state: S, payload: GroupHealth): void;
  [PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER_LOADING](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [PersonalMutationTypes.SET_CUSTOMER](state: State, customer: any) {
    state.customer = customer;
  },
  [PersonalMutationTypes.SET_CUSTOMER_AGES](state: State, ages: Array<number>) {
    state.ages = ages;
  },
  [PersonalMutationTypes.SET_CUSTOMER_MEMBERS](state: State, members: Array<Generics>) {
    state.members = members;
  },
  [PersonalMutationTypes.SET_CUSTOMER_RESULT](state: State, result: Result) {
    state.result = result;
  },
  [PersonalMutationTypes.SET_CUSTOMER_LOADING](state: State, loading: boolean) {
    state.loading = loading;
  },
  [PersonalMutationTypes.SET_CUSTOMER_EXISTING_LEADS](state: State, existingLeads: any) {
    state.existingLeads = existingLeads;
  },
  [PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS](state: State, selectedLeads: any) {
    state.selectedLeads = selectedLeads;
  },
  [PersonalMutationTypes.SET_INSURED_TYPE_DISABLED](state: State, insured_type_disabled: boolean) {
    state.insured_type_disabled = insured_type_disabled;
  },
  [PersonalMutationTypes.SET_EXIST_CLICK](state: State, exist_click: boolean) {
    state.exist_click = exist_click;
  },
  [PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER](state: State, customerGroupHealth: GroupHealth) {
    state.customerGroupHealth = customerGroupHealth;
  },
  [PersonalMutationTypes.GET_GROUP_HEALTH_CUSTOMER](state: State, customerGroupHealth: GroupHealth) {
    state.customerGroupHealth = customerGroupHealth;
  },
  [PersonalMutationTypes.SET_GROUP_HEALTH_CUSTOMER_LOADING](state: State, loading: boolean) {
    state.loadingGroupHealth = loading;
  },
};
