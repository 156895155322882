import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vechile-details-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_drop_down = _resolveComponent("h-drop-down")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formPersonalDetails",
    model: _ctx.customer,
    "status-icon": "",
    rules: _ctx.rules,
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('company_email'),
          isType: "text",
          formProp: "email",
          modelValue: _ctx.customer.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.email) = $event)),
          placeHolder: _ctx.$t('company_email_placeholder'),
          maxLength: "62"
        }, null, 8, ["label", "modelValue", "placeHolder"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('company_name'),
          isType: "text",
          formProp: "name",
          modelValue: _ctx.customer.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.name) = $event)),
          placeHolder: _ctx.$t('company_name_placeholder'),
          maxLength: "62",
          onKey: _ctx.language === 'en' ? `return /[a-zA-Z\s' ]/i.test(event.key)` : ''
        }, null, 8, ["label", "modelValue", "placeHolder", "onKey"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('contact_number'),
          isType: "phone",
          formProp: "phone_number",
          isGroup: "Yes",
          maxLength: _ctx.customer.phone_country_code == '+971' ? '10' : '10',
          placeHolder: _ctx.$t('contact_number_placeholder'),
          modelValue: _ctx.customer.phone_number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_number) = $event)),
          countryCode: _ctx.customer.phone_country_code,
          "onUpdate:countryCode": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.phone_country_code) = $event))
        }, null, 8, ["label", "maxLength", "placeHolder", "modelValue", "countryCode"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('contact_person'),
          isType: "text",
          formProp: "contact_person",
          modelValue: _ctx.customer.contact_person,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.contact_person) = $event)),
          placeHolder: _ctx.$t('contact_person_placeholder'),
          maxLength: "62",
          onKey: _ctx.language === 'en' ? `return /[a-zA-Z\s' ]/i.test(event.key)` : ''
        }, null, 8, ["label", "modelValue", "placeHolder", "onKey"]),
        _createVNode(_component_h_drop_down, {
          isRequired: "",
          selectOptions: _ctx.selectOptions,
          label: _ctx.$t('no_of_members'),
          isType: "select",
          placeholder: _ctx.$t('no_of_members_placeholder'),
          formProp: "no_of_members",
          modelValue: _ctx.customer.no_of_members,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.no_of_members) = $event))
        }, null, 8, ["selectOptions", "label", "placeholder", "modelValue"]),
        _createVNode(_component_h_proceed, {
          onHandleClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.personalDetailsGroup.click(_ctx.formPersonalDetails, _ctx.inst))),
          fullscreenLoading: _ctx.loading
        }, null, 8, ["fullscreenLoading"])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}