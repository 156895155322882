import { GetterTree } from 'vuex';


import { RootState } from '@/store';
import { emirates, insurance_for } from '@/store/stateless'

import { Health, GroupHealth } from '@/core/types';

import { State } from './state';

export type Getters = {
  getCustomer(state: State): Health;
  // getCustomerGroupHealth(state: State): GroupHealth;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCustomer: (state) => state.customer,
  // getCustomerGroupHealth: (state) => state.customer,


  getNotValidCustomer: (state) => (age_cap) => {
    const { insured_type_id, age, members, insured_city, is_salary } = state.customer

    if(![emirates.dubai, emirates.abhu_dhabi].includes(insured_city) && insured_type_id === insurance_for.parents) return true
    if(insured_type_id === 4) return true

    if(insured_city === emirates.dubai) {
      if(!is_salary && [insurance_for.family, insurance_for.domestic_worker, insurance_for.parents].includes(insured_type_id)) return true
      if(age > age_cap && insured_type_id === insurance_for.myself) return true
      if(age >= age_cap && insured_type_id === insurance_for.parents) return true
      if([insurance_for.family, insurance_for.domestic_worker, insurance_for.parents].includes(insured_type_id)) {
          if(insured_type_id === insurance_for.parents && members.find(x => x.age >= age_cap)) return true
          if([insurance_for.family, insurance_for.domestic_worker].includes(insured_type_id) && members.find(x => x.age > age_cap)) return true
          if(insured_type_id === insurance_for.family && getCountRelationships(state.customer, 2) > 0 && getCountRelationships(state.customer, 1) === 0) return true
      }
    }

    return false;
  },

  getHasEditable: (state) => {
    const noEditable = state.customer.members.find(x => x.is_editable)
    if(noEditable) return true

    return false
  },
  getNotValidAge: (state) => {
    const ages:Array<number> = []
    /** Insurance Type */
    const myself = 1;
    // const domestics = 2;
    const family = 3;
    const parent = 5;

    /** Emirates */
    const dubai = 3;

    const { members, age, insured_type_id, insured_city } = state.customer

    if(members.length > 0) {
      /* eslint-disable */
      const member_ages:any = members.map(x => x.age)
      ages.push(member_ages)
    }
    ages.unshift(age)
    if([myself, family].includes(insured_type_id) && insured_city === dubai) return ages.flat().some(greaterThanOrEqualToSixtyFive)
    if([myself, family].includes(insured_type_id) && insured_city !== dubai) return ages.flat().every(greaterThanOrEqualToSixty)
    if([parent].includes(insured_type_id) && insured_city === dubai) return ages.flat().every(greaterThanOrEqualToNinety)
    
    return false
  },

  getAllExistingLeads: (state) => {
    if(state.existingLeads.length > 0) {
      return state.existingLeads.map(x => {
        let count = x.insurance_type == 1 ? x.leads.length : x.leads[0]?.lead_informations.length ?? 1;
        return {
          map_lead_id: x.id,
          customer_id: x.customer_id,
          is_declaration_submitted: x.is_declaration_submitted === 1 ? true : false,
          name: `<span style="color: #222261; font-weight: bold">Health</span> - ${getInsuranceFor(x.insured_type_id)} - ${count} ${count > 1 ? 'Members' : 'Member'}`
        }
      })
    }
    return []
  }
};

const getCountRelationships = (customer, value) => {
  return customer.members.filter(x => x.relationship === value).length
} 

const greaterThanOrEqualToSixtyFive = (value: number) => {
  return value >= 65
}

const greaterThanOrEqualToSixty = (value) => {
  return value >= 60
}

const greaterThanOrEqualToNinety = (value) => {
  return value >= 90
}

const getInsuranceFor = (insured_type_id) => {
  switch(insured_type_id) {
    case 1: return 'Myself';
    case 2: return 'Domestic Worker';
    case 3: return 'Family';
    case 4: return 'Employee';
    case 5: return 'Parent';
    default: return '';
  }
}