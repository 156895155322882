
import { currency } from "@/core/functions/currency";
import Quotes from "@/core/services/Quotes";
import router from "@/router";
import { store } from "@/store";
import { ElLoading } from "element-plus";
import { computed, defineComponent, onMounted, ref, toRef, watch } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import PersonalDetails from "@/core/services/PersonalDetails";
import { isCalled } from "@/store/stateless";

export default defineComponent({
  props: {
    quote: {
      type: Object,
      required: true,
    },
    expand: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(_, context) {
    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const folder = ref(process.env.VUE_APP_ASSETS_FOLDER);

    const quote = toRef(_, "quote");

    const copaySelection = computed(() => {
      return store.state.quotes.copay_selection;
    });

    const moreDetails = ref(false);
    const assetDomain = process.env.VUE_APP_ASSETS_DOMAIN;
    // const premiums = quote.value.memberPremiums;
    const premiums = computed(() => {
      return quote.value.memberPremiums;
    });
    const selectedPremium = ref(premiums.value[0]);

    watch(
      () => _.quote,
      (value) => {
        if (value) changeCopay(0);
      }
    );

    const changeCopay = (index) => {
      selectedPremium.value = premiums.value[index];
      // context.emit('copaySelection',{quote , selectedPremium})
      // updating in copaySelections
      const key = copaySelection.value.findIndex(
        (item) => item.id == quote.value.id
      );
      if (key == -1) {
        if (copaySelection.value.length < 3)
          copaySelection.value.push({
            id: quote.value.id,
            selectedPremium: selectedPremium.value,
          });
      } else {
        copaySelection.value.splice(key, 1);
        copaySelection.value.push({
          id: quote.value.id,
          selectedPremium: selectedPremium.value,
        });
      }

      console.log("copaySelection", copaySelection.value);
      window.sessionStorage.setItem(
        "copaySelection",
        JSON.stringify(copaySelection.value)
      );
    };

    const lang = computed(() => {
      const lang = window.localStorage.getItem("language");
      if (lang) return lang;

      return "en";
    });

    const compareList = computed(() => {
      return store.state.quotes.compare_list;
    });

    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const BuyNow = async () => {
      console.log("buy");
      // const loading = ElLoading.service({
      //   lock: true,
      //   text: "Loading",
      //   background: "rgba(0, 0, 0, 0.9)",
      // });

      isCalled.mainLoader = true;
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");
      // console.log(token);

      // eslint-disable-next-line
      const map_id: any = window.localStorage.getItem("map_id");
      const payload = {
        health_lead_map_id: map_id,
        selected_premium: selectedPremium.value,
        policy_id: quote.value.id,
      };
      if (token) {
        if (
          customer.value.insured_type_id == 3 ||
          customer.value.insured_type_id == 5
        ) {
          /*
           calling update health lead api to update the insurance type for family and parent from NA to Basic or enhanced
           */
          customer.value.update_insurance_type = true;
          customer.value.insurance_type = quote.value.insurance_type;

          await PersonalDetails.post(customer.value);
        }

        await Quotes.buyNow(payload);
      }

      // loading.close();

      isCalled.mainLoader = false;

      const route = router.resolve({ name: "CheckoutDetails" });
      window.location.href = route.href;
    };

    return {
      premiums,
      selectedPremium,
      changeCopay,
      assetDomain,
      moreDetails,
      domain,
      BuyNow,
      compareList,
      lang,
      folder,
    };
  },
});
