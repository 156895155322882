<template>
  <section ref="targetElement" class="compare-vechile page-content">
    <div class="container">
      <div class="compare-vechile-container main-container">
        <div class="compare-vechile_row">
          <div class="page-title plain">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0">{{ $t("compare_plan") }}</h2>
              <div class="custom-button text-center btn-back">
                <a @click="$router.go(-1)" class="btn"> {{ $t("back") }} </a>
              </div>
            </div>
          </div>

          <div class="compare-plans-table">
            <div class="compare-plans-table_block">
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <h-compare-details v-bind:quote="data" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("annual_limit") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p class="amount" v-if="isNaN(data.annual_limit)">{{ $t("AED") }} {{ data.annual_limit }}</p>
                          <p class="amount" v-else>{{ currency.format(data.annual_limit) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("scope_coverage") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <p class="amount">
                          <span v-if="lang == 'en'" v-html="data.medical_cover"></span>
                          <span v-else v-html="data.medical_cover_ar"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("network_list") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <p class="amount">
                          <span class="d-flex align-items-center justify-content-center" v-if="data.network_list">
                            <a
                              :href="`${domain}/${folder}/${data.network_list}`"
                              target="_blank"
                              title="download"
                              class="d-flex align-items-center justify-content-center"
                            >
                              <!-- <span class="a-link ">{{ $t("download") }}</span> -->
                              <i class="icon-svg download-icon"></i>
                            </a>
                            <a
                              v-if="data.policy_detail"
                              :href="`${domain}/${folder}/${data.policy_detail}`"
                              target="_blank"
                              title="view"
                              class="a-link"
                            >
                              <i class="icon-svg pdf-icon"></i>
                            </a>
                          </span>
                          <span v-else>
                              -
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("dental") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data?.top_section[3]?.status ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data?.top_section[3]?.status"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("maternity_coverage") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data?.top_section[1]?.status ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data?.top_section[1]?.status"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("deductible") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data?.top_section[2]?.status ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data?.top_section[2]?.status"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ $t("optical") }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data?.top_section[4]?.status ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data?.top_section[4]?.status"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <template v-for="(master,kindex) in benefitMaster"  :key="kindex">
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p>{{ master.title }}</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in master.benefits"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p>
                            {{ data }}
                          <!-- {{ data.more_details.filter((x) => x.benefit_id == master.id)?.[0]?.value ?? '-' }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in compareList"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="proceed">
                          <a @click.prevent="BuyNow(data)" class="btn">
                            {{ $t("proceed") }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { currency } from "@/core/functions/currency";
import { defineComponent, computed, ref, onMounted } from "vue";
import { store } from "@/store";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";
import { ElLoading } from "element-plus";
import Quotes from "@/core/services/Quotes";

export default defineComponent({
  setup() {
    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const folder = ref(process.env.VUE_APP_ASSETS_FOLDER);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

    const compareList = computed(() => {
      const data = JSON.parse(window.localStorage.getItem("compare_data"));
      console.log(data);
      return data;
      // return store.state.quotes.compare_list
    });

    const benefitMaster = computed(() => {
      return store.getters.getCompareBenefitsValue
    })

    // handleClick
    const BuyNow = async (quote) => {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.9)",
      });
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");

      // eslint-disable-next-line
      // const map_id: any = window.localStorage.getItem('map_id');
      const payload = {
        health_lead_map_id: window.localStorage.getItem("map_id"),
        selected_premium: quote.memberPremiums[0],
        policy_id: quote.id,
      };
      if (token) {
        await Quotes.buyNow(payload);
      }

      loading.close();

      const route = router.resolve({ name: "CheckoutDetails" });
      window.location.href = route.href;
    };

    const lang = computed(() => {
        const lang = window.localStorage.getItem("language");
        if(lang)
            return lang;

        return 'en';
    });

    const targetElement = ref(null);

    const scrollToElement = () => {
      if (targetElement.value) {
        targetElement.value.scrollIntoView({ behavior: 'smooth' });
      }
    };

    onMounted(async () => {
      
      scrollToElement()
      await Quotes.getBenefitMaster();
     
      
    })

    return {
      lang,
      captcha,
      compareList,
      domain,
      url,
      BuyNow,
      benefitMaster,
      folder,
      targetElement
    };
  },
});
</script>
