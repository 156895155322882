
import { store } from "@/store";
import { defineComponent, ref, onMounted, computed } from 'vue'
import Quotes from '@/core/services/Quotes';
import { quoteTab } from '@/core/utils'
import router from "@/router";
import { ElLoading } from 'element-plus'
import PersonalDetails from "@/core/services/PersonalDetails";
import { isCalled } from '@/store/stateless';

export default defineComponent({

    setup() {

        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)

        const insurance_type = ref('all');
        const count = ref(-1)

        let quotes = computed(() => {
            return store.getters.getQuotes
        });

        const compareList = computed(() => {
            return store.state.quotes.compare_list
        });

        const lang = computed(() => {
            const lang = window.localStorage.getItem("language");
            if(lang)
                return lang;

            return 'en';
        });

        const changeInsuranceType = async (value) => {
            insurance_type.value = value
            // eslint-disable-next-line
            // let map_id: any = window.localStorage.getItem('map_id');
            let map_id: any = router.currentRoute.value.query.map_id;
            if (!map_id) {
                map_id = window.localStorage.getItem('map_id');
            }
            else {
                window.localStorage.setItem('map_id', map_id)
            }

            const payload = {
                health_lead_map_id: map_id,
                insurance_type: value
            }
            // const loading = ElLoading.service({
            //     lock: true,
            //     text: 'Loading',
            //     background: 'rgba(0, 0, 0, 0.9)',
            // })
            isCalled.mainLoader = true
            await Quotes.getQuotes(payload);
            // console.log('quotes', quotes.value)

            if (value == 'all') {
                count.value = quotes.value.length;
                // const insured_city = store.state.personals.customer.insured_city
                if(count.value == 0 )
                {
                    window.localStorage.setItem('quotes_not_found','YES');
                    const route = router.resolve({ name: 'CallBack' });
                    window.location.href = route.href;
                }
                else {
                    // window.localStorage.setItem('quotes_not_found','NO');
                }
            }
            
                window.sessionStorage.setItem('insuranceTab', value);
            
            // loading.close()

            
            isCalled.mainLoader =false

        };

        onMounted(async () => {
            window.localStorage.setItem('quotes_not_found','NO');
            window.localStorage.setItem('health_declaration_issue','NO');


            // const tab = window.sessionStorage.getItem('insuranceTab');

            // if(tab) {
            //     if(tab != 'all')
            //         await changeInsuranceType('all')

            //     if(['all','basic','enhanced'].includes(tab)){
            //         insurance_type.value = tab;
            //         await changeInsuranceType(insurance_type.value)
            //     }
            // }
            // else
                await changeInsuranceType('all')


            compareList.value.splice(0,compareList.value.length)
            const pd = window.sessionStorage.getItem('pd');
            // console.log(pd);
            if(pd) {
                // console.log('pd',JSON.parse(pd));
                await PersonalDetails.setCustomer(JSON.parse(pd));
            }
            // console.log('customer',customer.value);

            

            // changeInsuranceType(insurance_type.value)

        });

        // const compareList = ref<any[]>([])

        const addToCompare = (e:any) => {
            const index = compareList.value.findIndex((item) => item == e);
            if (index == -1) {
                if(compareList.value.length < 3)
                    compareList.value.push(e);
            } else {
                compareList.value.splice(index, 1);
            }


            // console.log('compareList',compareList.value)
        };


        return {
            insurance_type,
            changeInsuranceType,
            quotes,
            quoteTab,
            domain,
            url,
            count,
            addToCompare,
            compareList,
            lang
        }

    },
})
