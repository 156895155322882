import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { MemberCountMutationTypes } from '@/core/enum/GroupHealth/MemberCount/Mutations';
import { MemberCountActionTypes } from '@/core/enum/GroupHealth/MemberCount/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { isCalled } from '@/store/stateless';
import { da } from 'element-plus/es/locale';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [MemberCountActionTypes.GET_GROUP_HEALTH_MEMBER_COUNT](
    { commit }: AugmentedActionContext
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MemberCountActionTypes.GET_GROUP_HEALTH_MEMBER_COUNT]({ commit }) {
    try {
      const payload = {
        lang: window.localStorage.getItem('language'),
      }
      const data = await axios.post(Links.GET_GROUP_HEALTH_MEMBER_COUNT, payload);
      commit(MemberCountMutationTypes.SET_GROUP_HEALTH_MEMBER_COUNT, data.data.data.member_count_list);
    }
    catch (err) {
      return err;
    }
  },
};
